import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import Actions from '@components/Actions';
import { isSafari } from '@util/browser';
import SanityImage from '@components/SanityImage';
import Marquee from '@components/Marquee';
import ArrowRight from '@icons/ArrowRight.svg';
import Link from '@components/Link';

import * as styles from './SingleRowLogoMarquee.module.css';

const Card = ({ logo }) => {
  const LOGO_MAX_WIDTH = 80;
  const LOGO_MAX_HEIGHT = 26;

  const hasLink = logo.href && logo.href.length > 0;

  const inner = (
    <>
      <div className={styles.cardTitle}>{logo.image.alt}</div>
      <div className={styles.cardBottom}>
        <SanityImage
          priority={isSafari()}
          asset={logo.image.image.asset}
          alt={logo.image.alt}
          className={cn(styles.logo)}
          placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
          maxWidth={LOGO_MAX_WIDTH}
          maxHeight={LOGO_MAX_HEIGHT}
        />
        {hasLink && <ArrowRight className={cn(styles.arrowIcon)} />}
      </div>
    </>
  );

  return hasLink ? (
    <Link
      prefetch={false}
      href={logo.href}
      className={cn(styles.card, styles.hasLink)}
    >
      {inner}
    </Link>
  ) : (
    <div className={styles.card}>{inner}</div>
  );
};

const CardsMarquee = ({ logos, repeatCount = 2 }) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const MARQUEE_BASE_SPEED = 0.2;

  const totalItems = logos.length;

  const speed = useMemo(() => {
    const speed = MARQUEE_BASE_SPEED / totalItems;
    return Math.max(0.0125, speed);
  }, [totalItems]);

  return (
    <div
      className={styles.marquee}
      onPointerEnter={() => setIsPlaying(false)}
      onPointerLeave={() => setIsPlaying(true)}
    >
      <Marquee
        repeatCount={repeatCount}
        speed={speed}
        shouldPlay={isPlaying}
        onlyPlayInView={false}
        style={{
          '--marquee-item-gap': 'var(--space-m)',
        }}
      >
        {logos.map((logo, index) => {
          return <Card key={index} logo={logo} />;
        })}
      </Marquee>
    </div>
  );
};

export const SingleRowLogoMarquee = ({ logos, actions }) => {
  if (logos.length === 0) {
    return null;
  }

  return (
    <div className={styles.singleRowLogoMarquee}>
      <div className={styles.wrapper}>
        <CardsMarquee logos={logos} />
        <div className={styles.action}>
          <Actions actions={actions} />
        </div>
      </div>
    </div>
  );
};
