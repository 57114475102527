/**
 * Utilities for working with webinars.
 * Kept in a separate file, so they can be used
 * both client- and server-side (no node.js imports)
 */

/**
 * Checks if a webinar can be considered archived
 *
 * @param{object} webinar
 * @param{Date} reference
 * @returns {boolean}
 */
export const isArchivedWebinar = (webinar, reference = new Date()) => {
  const hasYoutube =
    !!webinar.youtubeArchiveId && webinar.youtubeArchiveId !== '';
  const hasWistia = !!webinar.wistiaArchiveId && webinar.wistiaArchiveId !== '';
  return !isUpcomingWebinar(webinar, reference) && (hasYoutube || hasWistia);
};

/**
 * Checks if a webinars is upcoming. A currently active webinar
 * is considered to be upcoming.
 *
 * @param{object} webinar
 * @param{Date} reference
 * @returns {boolean}
 */
export const isUpcomingWebinar = (webinar, reference = new Date()) => {
  return (
    new Date(webinar?.date?.utc) > reference - webinar.duration * 60 * 1000
  );
};

/**
 * Determines if a webinar should show its active state
 * or is still in registration mode.
 *
 * @param{object} webinar
 * @param{number} hourOffset
 * @param{Date} reference
 * @returns{boolean}
 */
export const isActiveWebinar = (
  webinar,
  hourOffset = 0,
  reference = new Date(),
) => {
  const { date, debugAlwaysActive } = webinar;

  if (debugAlwaysActive) return true;

  return new Date(date.utc) - hourOffset * 60 * 60 * 1000 < reference;
};

/**
 * Finds the next webinar in a list of webinars.
 *
 * @param{Array} webinars
 * @param{Date} reference
 * @returns{object|null}
 */
export const findNextWebinar = (webinars, reference = new Date()) => {
  const sorted = [...webinars].sort(
    (a, b) => new Date(a.date.utc) - new Date(b.date.utc),
  );

  return (
    sorted.find(({ date, duration }) => {
      return new Date(date.utc) > reference - duration * 60 * 1000;
    }) || null
  );
};

/**
 * Sorts webinars by date
 *
 * @param{Array<T>} webinars
 * @params{"ASC"|"DESC"} direction
 * @returns{Array<T>}
 */
export const sortWebinarsByDate = (webinars, direction = 'ASC') => {
  return webinars.sort((a, b) => {
    const dateA = new Date(a.date.utc);
    const dateB = new Date(b.date.utc);

    return direction === 'ASC' ? dateA - dateB : dateB - dateA;
  });
};

/**
 * Creates pagination for a list of webinars
 *
 * @params{Array<T>} webinars
 * @params{number} page
 * @params{number} perPage
 * @returns{Array<T>}
 */
export const paginateWebinars = (webinars, page = 1, perPage = 3) => {
  return webinars.slice((page - 1) * perPage, page * perPage);
};

/**
 * Performs a full text search on the webinars based on title
 *
 * @params{Array<T>} webinars
 * @params{string} query
 * @returns{Array<T>}
 */
export const searchWebinars = (webinars, query) => {
  const lowerQuery = query.toLowerCase();
  return webinars.filter(({ title }) => {
    return title.toLowerCase().includes(lowerQuery);
  });
};

/**
 * Turns a webinar's date time portion into the predefined
 * timezone to output all webinar times.
 *
 * @param{object} webinar
 * @returns {string}
 */
export const formatWebinarTime = ({ date }) => {
  const res = new Date(date.utc).toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour: '2-digit',
    minute: '2-digit',
    seconds: false,
  });

  return `${res.replace(' ', '')} ET`;
};
